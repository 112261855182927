import { Inject, Injectable, Injector, Optional } from '@angular/core';
import { isNumber } from 'util';
import { MnbColor } from '@shared-lib/modules/core/utils/mnb-color-util';
import { DateSpan } from '@shared-lib/modules/core/model/mnb-time.model';

// hint this not nice (since its from the suite) - but its only temporary anyways
import { LEGACY_MNB_MODEL_SERVICE } from '@minubo-suite/shared/modules/legacy/services/legacy-model-service.provider';
import { LanguageService } from '@shared-lib/modules/core/services/language/language.service';
import { MnbModelConfig, MnbModelLoader, ModelAttribute, ModelChannel, ModelData, ModelDimension, ModelEntity, ModelMeasure, ModelMeasureDetailed } from '@shared-lib/modules/model/mnb-model.model';


@Injectable()
export class MnbModelService  {

    private modelLoader: MnbModelLoader;

    private measuresPromise: Promise<Array<ModelMeasure>>;
    private measurePromises = new Map<string, Promise<ModelMeasure>>();

    private attributesPromise: Promise<Array<ModelAttribute>>;
    private attributePromises = new Map<string, Promise<ModelAttribute>>();

    private planNamesPromise: Promise<Array<string>>;

    private entitiesPromise: Promise<ModelEntity[]>;
    private channelsPromise: Promise<ModelChannel[]>;

    constructor(
        @Optional() @Inject(LEGACY_MNB_MODEL_SERVICE) private mnbModelService: any,
        @Optional() @Inject('mnbModelConfig') config: MnbModelConfig,
        injector: Injector,
        private languageService: LanguageService,
    ) {
        this.modelLoader = config ? injector.get(config.loader) : null;
    }

    /**
     * Gets a list of all 'new' measures, with new names/codes
     */
    getMeasures(): Promise<Array<ModelMeasure>> {
        if (!this.measuresPromise) {
            this.measuresPromise = new Promise((resolve, reject) => {
                const p = this.mnbModelService ? this.mnbModelService.getMeasures() : this.modelLoader.getMeasures();
                p.then((measures: Array<ModelMeasure>) => {
                    measures.forEach(measure => {
                        if (measure.planMeasure) {
                            measure.planMeasure = measures.find(m => m.code === measure.planMeasure.code);
                        }
                    });
                    resolve(measures);
                });
            });
        }
        return this.measuresPromise;
    }

    /**
     * Gets a single measure using its unique code. Returns the new name if the measure has one
     * @param code the code used to identify the measure
     */
    async getMeasure(code: string): Promise<ModelMeasure> {
        if (!this.measurePromises.has(code)) {
            this.measurePromises.set(code, new Promise((resolve, reject) => {
                this.getMeasures().then((measures: Array<ModelMeasure>) => {
                    let measure: ModelMeasure = null;
                    measures.forEach((m) => {
                        if (m.code === code) {
                            measure = m;
                        }
                    });
                    if (!measure) {
                        const err = 'measure not found ' + code;
                        reject(err);
                    } else {
                        resolve(measure);
                    }
                });
            }));
        }
        return this.measurePromises.get(code);
    }

    /**
     * Gets a list of all 'new' attributes, with new names/codes
     */
    getAttributes(): Promise<Array<ModelAttribute>> {
        if (!this.attributesPromise) {
            this.attributesPromise = new Promise((resolve, reject) => {
                const p = this.mnbModelService ? this.mnbModelService.getAttributes() : this.modelLoader.getAttributes();
                p.then((attributes: Array<ModelAttribute>) => {
                    // TODO: The decission what to be listed here should be done in the backend (eg. attribute.isSupportingTimeHistogram)
                    const timeAttributesWithHistogram: string[] = ['timeDate', 'timeDateYearWeek', 'timeDateYearMth', 'timeDateYearQrt', 'timeDateYear'];

                    attributes.forEach(attribute => {
                        attribute.isSupportingTimeHistogram = timeAttributesWithHistogram.includes(attribute.code);
                    });

                    resolve(attributes);
                });
            });
        }
        return this.attributesPromise;
    }

    /**
     * Gets a single attribute using its unique code. Returns the new name if the attribute has one
     * @param code the code used to identify the attribute
     */
    getAttribute(code: string): Promise<ModelAttribute> {
        if (!this.attributePromises.has(code)) {
            this.attributePromises.set(code, new Promise((resolve, reject) => {
                this.getAttributes().then((attributes: Array<ModelAttribute>) => {
                    let attribute: ModelAttribute = null;
                    attributes.forEach((a) => {
                        if (a.code === code) {
                            attribute = a;
                        }
                    });

                    if (!attribute) {
                        const err = 'attribute not found ' + code;
                        reject(err);
                    } else {
                        resolve(attribute);
                    }
                });
            }));
        }
        return this.attributePromises.get(code);
    }

    public refreshAttributes() {
        if (this.mnbModelService) {
            this.mnbModelService.refreshAttributes();
        }

        this.attributePromises.clear();
        this.attributesPromise = null;
    }

    public refreshMeasures() {
        if (this.mnbModelService) {
            this.mnbModelService.refreshMeasures();
        }

        this.measurePromises.clear();
        this.measuresPromise = null;
    }

     /**
     * Gets a list of all attributes, that can be displayed by histogram
     */
    getHistogramAttributes(includeHAlfYears?: boolean): Promise<Array<ModelAttribute>> {
        return this.getAttributes().then(attributes => {
            return attributes.filter(attribute => attribute.isSupportingTimeHistogram || (includeHAlfYears && attribute.code === 'timeDateYearHyr'));
        });
    }

    isHistogramAttribute(attribute: ModelAttribute) {

    }

    getDefaultHistogramAttributeCode(dateSpan: DateSpan): string {
        const to = dateSpan.to;
        const from = dateSpan.from;
        const differenceInMonths = to.getMonth() - from.getMonth() + (12 * (to.getFullYear() - from.getFullYear()));
        const differenceInDays = Math.abs(Math.floor((Date.UTC(from.getFullYear(), from.getMonth(), from.getDate()) - Date.UTC(to.getFullYear(), to.getMonth(), to.getDate()) ) / (1000 * 60 * 60 * 24)));

        let attributeCode =  '';

        if (differenceInMonths > 24) {
            attributeCode = 'timeDateYear';
      /*  } else if (differenceInMonths > 12) { //half years are not supported in histograms
            attributeCode = 'timeDateYearHyr';*/
        } else if (differenceInMonths > 8) {
            attributeCode = 'timeDateYearQrt';
        } else if (differenceInDays > 91) {
            attributeCode = 'timeDateYearMth';
        } else if (differenceInDays > 31) {
            attributeCode = 'timeDateYearWeek';
        } else {
            attributeCode = 'timeDate';
        }

        return attributeCode;
    }


    getDataLevel(code: string): Promise<ModelData> {
        return this.mnbModelService.getDataLevel(code);
    }

    getDataLevels(): Promise<Array<ModelData>> {
        return this.mnbModelService.getDataLevels();
    }

    getDimension() {
        return this.mnbModelService.getDimension();
    }

    getDimensions(): Promise<Array<ModelDimension>> {
        return this.mnbModelService.getDimensions();
    }

    getDateReference() {
        return this.mnbModelService.getDateReference();
    }

    getDateReferences(): Array<ModelData> {
        return this.mnbModelService.getDateReferences();
    }

    getModifiers(): Array<ModelData> {
        return this.mnbModelService.getModifiers();
    }

    getMeasureTypes(): Array<ModelData> {
        return this.mnbModelService.getMeasureTypes();
    }

    getAggregators(): Array<ModelData> {
        return this.mnbModelService.getAggregators();
    }

    getPlanNames(): Promise<Array<string>> {
        if (!this.planNamesPromise) {
            this.planNamesPromise = this.modelLoader.getPlanNames();
        }
        return this.planNamesPromise;
    }

    getMeasureDetail(code: string): Promise<ModelMeasureDetailed> {
        return this.mnbModelService.getMeasureDetail(code);
    }

    getAttributeDetail(code: string): Promise<ModelAttribute> {
        return this.mnbModelService.getAttributeDetail(code);
    }

    buildHelpLink(code: string, isMeasureCode: boolean): string {
        const type = isMeasureCode ? 'measures' : 'attributes';
        const url = '#/help/data/model/' + type + '/' + code;
        return url;
    }

    // Navigate to the help page for measure or attribute field
    navigateToHelp(code: string, isMeasureCode: boolean): void {
        window.open(this.buildHelpLink(code, isMeasureCode), '_blank');
    }

    public getColorByIndex(sortIndex: number): MnbColor {
        return this.getColor(null, null, sortIndex);
    }

    public getColor(attribute: ModelAttribute, value: string, sortIndex: number): MnbColor {

        if (attribute) {
            const customColor = this.getColorByAttributeAndValue(attribute, value);
            if (customColor) {
                return customColor;
            }
        }

        return MnbColor.ORDERED_COLORS[sortIndex];

    }

    private getColorByAttributeAndValue(attribute: ModelAttribute, value: string): MnbColor {
        if (value == null || value === this.languageService.getEmptyLabel()) {
            return MnbColor.RED_LIGHTER_2;
        }

        if (attribute.colors && attribute.colors.find(color => color.value === value)) {
            const config = attribute.colors.find(color => color.value === value);
            return MnbColor.get(config.color, config.shade);
        }
        return null;
    }

    getEntities(): Promise<ModelEntity[]> {
        if (!this.entitiesPromise) {
            this.entitiesPromise = this.modelLoader.getEntities();
        }
        return this.entitiesPromise;
    }

    getEntity(codeOrId: string): Promise<ModelEntity> {
        const id = isNumber(codeOrId) ? parseInt(codeOrId) : null;
        const code = isNumber(codeOrId) ? null : codeOrId;
        return this.getEntities().then(entities => entities.filter(e => e.code === code || e.id === id)[0]);
    }

    getChannels(): Promise<ModelChannel[]> {
        if (!this.channelsPromise) {
            this.channelsPromise = this.modelLoader.getChannels();
        }
        return this.channelsPromise;
    }

    async getChannel(data: {[code: string]: string}): Promise<ModelChannel> {
        const level = data['chnLevel1'] !== undefined && data['chnLevel2'] !== undefined ? 2 : data['chnLevel1'] !== undefined ? 1 : 0;

        if (!level) {
            return Promise.resolve(null);
        }
        return this.getChannels().then(entities => entities.filter(e => {
            if (e.level !== level) {
                return false;
            }
            if (e.level1Value !== data['chnLevel1']) {
                return false;
            }

            if (level === 2 &&  e.level2Value !== data['chnLevel2']) {
                return false;
            }
            return true;
        })[0]);
    }
}
