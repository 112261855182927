import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Location } from '@angular/common';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PortalReport } from '@minubo-portal/modules/api/models/api-report.model';
import { ReportsFilterModel, ReportsFiltersModel } from '@minubo-portal/modules/reports/model/reports-filters.model';
import { ApiDataService } from '@minubo-portal/modules/api/services/api-data.service';
import { ApiPortalService } from '@minubo-portal/modules/api/services/api-portal.service';
import { ApiReportService } from '@minubo-portal/modules/api/services/api-report.service';
import { MnbBusyStatus } from '@shared-lib/modules/core/model/mnb-core-busy-status.model';
import { deepCopy } from '@shared-lib/modules/core/utils/deep-copy.util';
import { EntityType } from '@shared-lib/modules/data/model/mnb-data-entity.model';
import { QueryFilter, QuerySettingsComparisonFilter, QuerySettingsTimeFilter } from '@shared-lib/modules/data/model/mnb-data-query.model';
import { ReportData, ReportSettings, getReportType } from '@shared-lib/modules/data/model/mnb-data-reports.model';
import { MnbQuickTimeFilterValue } from '@shared-lib/modules/filters/components/quick-time-filter/mnb-quick-time-filter.component';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, first, map, mergeMap, takeUntil, tap, share, switchMap, withLatestFrom } from 'rxjs/operators';
import { LocalSettingsStore } from '@minubo-portal/utils/local-settings-store.util';
import { ReportsReportPageContext } from '@minubo-portal/modules/reports/pages/report/reports-report-page.component';
import { cleanEmptyFilters, nullIfNothingSet } from '@minubo-portal/modules/reports/utils/report-filter.utils';
import { updateRouteWithFilters } from '@shared-lib/utils/route.utils';
import { ViewSharingService } from '@minubo-portal/modules/portal-modal/view-sharing/service/view-sharing.service';


@Component({
    selector: 'reports-template',
    templateUrl: './reports-template.component.html'
})
export class ReportsTemplateComponent implements OnInit, OnDestroy {

    private destroy$ = new Subject<void>();

    constructor(
        private route: ActivatedRoute,
        private location: Location,
        private router: Router,
        private apiReportService: ApiReportService,
        private apiDataService: ApiDataService,
        private apiPortalService: ApiPortalService,
        private settingsStore: LocalSettingsStore<ReportSettings>,
        private viewSharingService: ViewSharingService<ReportSettings>,
    ) { }

    public report$: Observable<PortalReport>;
    public viewSettings$ = new Subject<ReportSettings>();
    public filters$ = new BehaviorSubject<ReportsFiltersModel>({
        filters: []
    });
    public originalTimeFilters$: Observable<MnbQuickTimeFilterValue | null>;

    public rawFilters$: Observable<QueryFilter[]> = this.filters$.pipe(
      map(model => model.filters.map(f => f.filter))
    );
    public rawTimeFilter$: Observable<MnbQuickTimeFilterValue> = this.filters$.pipe(
        map(model => ({timeFilter: model.timeFilter, comparisonFilter: model.comparisonFilter}))
    );

    public context$: Observable<ReportsReportPageContext>;

    public dataViewSettings: ReportSettings;
    public currentFilters?: QueryFilter[];

    public originalViewSettingsFormValue: ReportsTemplateFormValue;

    private _form: FormGroup;


    public get form(): FormGroup {
        return this._form;
    }

    @Input() public enableComparison = true;

    @Input()
    public set form(form: FormGroup) {
        this._form = form;
        this.initForm();
    }

    @Input()
    public set viewSettings(viewSettings: ReportSettings) {
        this.viewSettings$.next(viewSettings);

        if (viewSettings == null) {
            this.viewSharingService.setActiveViewSettings(null);
            return;
        }

        // send view settings to view sharing service
        const reportType = getReportType(viewSettings);
        const innerViewSettings = viewSettings[reportType];

        const innerViewSettingsInForm = {};
        for (const key of Object.keys(innerViewSettings)) {
            if (this.form.contains(key)) {
                innerViewSettingsInForm[key] = innerViewSettings[key];
            }
        }

        const viewSettingsInForm = {};
        viewSettingsInForm[reportType] = innerViewSettingsInForm;
        const cleanedViewSettingsInForm = cleanEmptyFilters(viewSettingsInForm);
        this.viewSharingService.setActiveViewSettings(cleanedViewSettingsInForm);

    }

    @Output() public viewSettingsApplied = new EventEmitter<ReportsTemplateViewSettingsAppliedEvent>();
    @Output() public viewSettingsFormValueChanged = new EventEmitter<ReportsTemplateFormValue>();
    @Output() public dataChanged = new EventEmitter<ReportsTemplateDataChangeEvent>();

    public load = new MnbBusyStatus();

    ngOnInit(): void {

        this.report$ = this.route.data.pipe(
            map((data: {context: ReportsReportPageContext}) => {
               return data.context.report;
            }),
        );
        this.originalTimeFilters$ = this.report$.pipe(
            map(report => {
                const settingsForTypeCode = report.settings[report.typeCode] as MnbQuickTimeFilterValue | undefined;
                const hasRequiredFields = (settings: any): settings is MnbQuickTimeFilterValue =>
                    'timeFilter' in settings || 'comparisonFilter' in settings;

                return settingsForTypeCode && hasRequiredFields(settingsForTypeCode)
                    ? settingsForTypeCode
                    : null;
            })
        );

        this.context$ = this.route.data.pipe(
            map((data: {context: ReportsReportPageContext}) => data.context)
        );

        const portalEntity$ = this.report$.pipe(
            filter(report => !!report),
            distinctUntilChanged((a: PortalReport, b: PortalReport) => a.id === b.id),
            mergeMap(r => this.apiPortalService.loadEntity(EntityType.REPORT, r.id))
        );

        this.report$.pipe(
            filter(report => !!report),
            takeUntil(this.destroy$)
        ).subscribe(r => {
            this.viewSettingsApplied.emit({
                report: r, viewSettings: null
            });
        });

        this.viewSharingService.applyViewSettings$.pipe(
            withLatestFrom(portalEntity$, this.context$),
            takeUntil(this.destroy$)
        ).subscribe(([viewSettings, portalEntity, context]) => {
            const portalFilters = portalEntity.settings.filters || [];
            this.applyLocalSettings(portalFilters, viewSettings[context.report.typeCode]);
        });

        // Setting up configured / additional filters
        combineLatest([portalEntity$, this.context$])
            .pipe(takeUntil(this.destroy$))
            .subscribe(([portalEntity, context]) => {
                const portalFilters = portalEntity.settings.filters || [];
                const localSettings: ReportsTemplateFormValue | null = context.localSettings ? context.localSettings[context.report.typeCode] : null;
                this.applyLocalSettings(portalFilters, localSettings);
            });

        this.filters$.pipe(
            takeUntil(this.destroy$)
        ).subscribe((filters) => {
            const filtersValue = filters.filters.map(filterModel => filterModel.filter);
            this.form.get('filters').setValue(filtersValue);
        });

        // automatically load report data
        combineLatest([this.report$, this.viewSettings$])
            .pipe(
                filter(([_, viewSettings]) => !!viewSettings),
                tap(() => this.load.reset()),
                debounceTime(200),
                tap(([report, viewSettings]) => {
                    this.dataChanged.emit({
                        report,
                        viewSettings,
                        data: null
                    });
                }),
                switchMap(([report, viewSettings]) => {
                    return this.apiReportService.loadReportData(report.id, viewSettings).pipe(
                        map(data => [report, viewSettings, data] as [PortalReport, ReportSettings, ReportData])
                    );
                }),
                share(),
                takeUntil(this.destroy$)
            )
            .subscribe(([report, viewSettings, data]) => {
                this.load.done();
                this.dataChanged.emit({
                    report: report,
                    viewSettings: viewSettings,
                    data: data
                });
            });


        // subscription to keep the localStorage in-sync
        combineLatest([this.report$, this.viewSettings$])
            .pipe(
                debounceTime(300),
                filter(([_, viewSettings]) => viewSettings != null),
                takeUntil(this.destroy$))
            .subscribe(([report, viewSettings]) => {
                const cleanedSettings = nullIfNothingSet(cleanEmptyFilters(viewSettings));
                updateRouteWithFilters(cleanedSettings, this.location, this.router, this.route);

                this.settingsStore.store('report', report.id, viewSettings);
            });
    }

    private applyLocalSettings(portalFilters: QueryFilter[], localSettings: ReportsTemplateFormValue) {
        const localFilters = localSettings ? localSettings.filters : [];
        const filterModels = portalFilters.map(settingsFilter => {
            settingsFilter.values = settingsFilter.values || [];

            let updatedFilter = (this.currentFilters || []).find(f => f.attributeCode === settingsFilter.attributeCode) || settingsFilter;
            const localFilter = localFilters.find(f => f.attributeCode === updatedFilter.attributeCode);
            if (!!localFilter) {
                updatedFilter = localFilter;
            }
            const filterModel: ReportsFilterModel = {
                filter: updatedFilter,
                originalFilter: deepCopy(settingsFilter)
            };
            return filterModel;
        });
        const newFilters: ReportsFiltersModel = {
            filters: filterModels
        };
        if (localSettings && localSettings.timeFilter) {
            newFilters.timeFilter = localSettings.timeFilter;
        }
        if (localSettings && localSettings.comparisonFilter) {
            newFilters.comparisonFilter = localSettings.comparisonFilter;
        }

        // localSettings can contain report-specific keys which we don't want to check on manually in the reports-template
        if (localSettings) {
            const anyLocalSettings = localSettings as unknown;
            for (const field of Object.keys(anyLocalSettings)) {
                if (this.form.contains(field)) {
                    this.form.get(field).setValue(anyLocalSettings[field]);
                }
            }
        }
        this.filters$.next(newFilters);
    }

    private initForm() {

        this.form.valueChanges.pipe(debounceTime(300), takeUntil(this.destroy$))
            .subscribe(() => {
                if (!this.originalViewSettingsFormValue) {
                    this.originalViewSettingsFormValue = this.form.value;
                }

                this.viewSettingsFormValueChanged.emit(this.form.value);
            });
    }

    public loadAdhocFeedUrl(report: PortalReport, viewSettings: ReportSettings) {
        this.apiReportService.loadReportAdhocFeedUrl(report.id, viewSettings)
            .pipe(first())
            .subscribe(data => window.open(data.url, '_blank'));
    }

    public onTimeFilterChange(value: MnbQuickTimeFilterValue): void {
        this.form.get('timeFilter').setValue(value.timeFilter);
        const comparisonFilter = this.form.get('comparisonFilter');
        if (comparisonFilter) {
            comparisonFilter.setValue(value.comparisonFilter);
        }
    }

    public onFilterChange(value: QueryFilter, filterModel: ReportsFilterModel) {
        filterModel.filter = value;
        this.filters$.next({
            ...this.filters$.value
        });
    }

    public loadFilterValues = (attributeCode: string, filterSearch?: string): Promise<any> => {
        return this.apiDataService.loadAttributeData(attributeCode, filterSearch).toPromise();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}

export interface ReportsTemplateViewSettingsAppliedEvent {
    report: PortalReport;
    viewSettings: ReportSettings;
}

export interface ReportsTemplateDataChangeEvent {
    report: PortalReport;
    viewSettings: ReportSettings;
    data: ReportData;
}

export interface ReportsTemplateFormValue {
    timeFilter?: QuerySettingsTimeFilter;
    comparisonFilter?: QuerySettingsComparisonFilter;
    filters?: QueryFilter[];
    selectedMeasureCodes?: string[];
}
