import { Component, forwardRef, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { isNullOrUndefined } from 'util';

@Component({
    selector: 'mnb-switch-toggle',
    template: `
    <div class="input-switch-toggle" [ngClass]="{ 'toggle-disabled': disabled, 'toggle-on': isSwitchedOn, 'toggle-small': isSmall }">
      <div class="switch-toggle-text-holder">
        <span class="switch-toggle-text">
          {{ (disabled ? 'GENERAL.LABEL.DISABLED' : isSwitchedOn ? 'GENERAL.LABEL.ON' : 'GENERAL.LABEL.OFF') | translate }}
        </span>
      </div>
      <div class="switch-toggle-slider">
        <span class="switch-toggle-button"></span>
      </div>
    </div>
  `,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => MnbSwitchToggleComponent),
        multi: true
    }]
})

export class MnbSwitchToggleComponent {

    @Input() set value(value: boolean) {
        this.isSwitchedOn = value;
    }

    @Output() valueChange = new EventEmitter<boolean>();

    @Input() disabled = false;
    @Input() isSmall = false;

    public isSwitchedOn: boolean;
    private propagateChange = (_: any) => { };

    constructor() { }

    @HostListener('click')
    onclick() {
        if (!this.disabled) {
            this.isSwitchedOn = !this.isSwitchedOn;
            this.propagateChange(this.isSwitchedOn);
            this.valueChange.emit(this.isSwitchedOn);
        }
    }

    writeValue(value: boolean): void {
        if (!isNullOrUndefined(value)) {
            this.isSwitchedOn = value;
        }
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void { }

}
