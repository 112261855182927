import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TimeComparisonFilter, TimeComparisonFilterOptions, TimeComparisonOption, TimeComparisonOptionCode, TimeFilter, TimeFilterOptions } from '@shared-lib/modules/core/services/time/time.model';
import { TimeTypeCode, QuerySettingsTimeFilterOptionCode } from '@shared-lib/modules/data/model/mnb-data-query.model';
import { MnbDateSpanConfig, MnbDateSpanPipe } from '@shared-lib/modules/core/pipes/date-span.pipe';
import { DateSpan } from '@shared-lib/modules/core/model/mnb-time.model';
import { MnbTimeService } from '@shared-lib/modules/core/services/time/time.service';
import { Periods } from '@shared-lib/modules/core/model/period-option.model';
import { ExtendedComparisonCode } from '@shared-lib/modules/core/services/time/time.model';


@Injectable({
    providedIn: 'root'
})
export class TimeFilterService  {

    constructor(
        private translateService: TranslateService,
        private dateSpanPipe: MnbDateSpanPipe,
        private timeService: MnbTimeService
    ) {}

    public hasFiscalYearConfigured(): boolean {
        return this.timeService.hasFiscalYearConfigured();
    }

    public getTimeLabel(timeFilter: TimeFilter, withCustomDateSpan?: boolean, customLabel?: string): Promise<string> {
        if (customLabel) {
            return Promise.resolve(customLabel);
        }

        if (!timeFilter) {
            return Promise.resolve('-');
        }

        const option = TimeFilterOptions.getByCode(timeFilter.optionCode);

        return this.translateService.get(option.label).toPromise().then(label => {
            if (withCustomDateSpan && option.code === QuerySettingsTimeFilterOptionCode.CUSTOM) {
                return label + ' (' + this.getTimeFilterDateSpanText(timeFilter) +  ')';
            } else {
                return label;
            }
        });
    }

    public getTimeLabelWithDateSpan(timeFilter: TimeFilter, customLabel?: string, config?: MnbDateSpanConfig, referenceFromDate?: Date): Promise<string> {
        return this.getTimeLabel(timeFilter, false, customLabel).then(label => {
            if (timeFilter) {
                return label + ' (' + this.getTimeFilterDateSpanText(timeFilter, config, referenceFromDate)  + ')';
            } else {
                return label;
            }
        });
    }

    public getTimeFilterDateSpanText(timeFilter: TimeFilter, config?: MnbDateSpanConfig, referenceFromDate?: Date): string {
        const timeSpan = this.getTimePeriod(timeFilter, referenceFromDate);
        return this.dateSpanPipe.transform(timeSpan, config);
    }

    public getComparisonLabel(comparisonFilter: TimeComparisonFilter, timeFilter: TimeFilter, customLabel?: string): Promise<string> {
        return this.getInternalComparisonLabel(comparisonFilter, timeFilter, false, customLabel);
    }

    public getComparisonLabelWithDateSpan(comparisonFilter: TimeComparisonFilter, timeFilter: TimeFilter, customLabel?: string, referenceFromDate?: Date): Promise<string> {
        return this.getComparisonLabel(comparisonFilter, timeFilter, customLabel).then(label => {
            const comparisonSpan = this.getComparisonPeriod(timeFilter, comparisonFilter, referenceFromDate);
            return label + ' (' + this.dateSpanPipe.transform(comparisonSpan)  + ')';
        });
    }

    public getShortComparisonLabel(comparisonFilter: TimeComparisonFilter, timeFilter: TimeFilter, customLabel?: string): Promise<string> {
        return this.getInternalComparisonLabel(comparisonFilter, timeFilter, true, customLabel);
    }

    public getShortComparisonLabelWithDateSpan(comparisonFilter: TimeComparisonFilter, timeFilter: TimeFilter, customLabel?: string, referenceFromDate?: Date): Promise<string> {
        return this.getShortComparisonLabel(comparisonFilter, timeFilter, customLabel).then(label => {
            const comparisonSpan = this.getComparisonPeriod(timeFilter, comparisonFilter, referenceFromDate);
            return label + ' (' + this.dateSpanPipe.transform(comparisonSpan)  + ')';
        });
    }

    private getInternalComparisonLabel(comparisonFilter: TimeComparisonFilter, timeFilter: TimeFilter, short: boolean, customLabel: string): Promise<string> {
        const option = this.getComparisonOption(comparisonFilter);

        if (!option) {
            return Promise.resolve('');
        }

        if (customLabel) {
            return Promise.resolve(customLabel);
        }

        const labelCode = short ? option.shortLabelCode : option.labelCode;

        if (option.code !== TimeComparisonOptionCode.OffsetByXPeriods) {
            return this.translateService.get(labelCode).toPromise();
        } else {
            return this.translateService.get(Periods.getLabelCode(comparisonFilter.typeCode, comparisonFilter.index === -1)).toPromise().then(period => {
                return this.translateService.get(labelCode, {
                    index: -comparisonFilter.index,
                    period: period
                }).toPromise();
            });
        }
    }

    public getComparisonOption(comparisonFilter: TimeComparisonFilter): TimeComparisonOption {
        if (!comparisonFilter) {
            return null;
        }

        if (comparisonFilter.fromDate && comparisonFilter.toDate) {
            return TimeComparisonFilterOptions.getByCode(TimeComparisonOptionCode.DateRange).withFilter(comparisonFilter);
        }

        if (comparisonFilter.optionCode === TimeComparisonOptionCode.AdjoiningPeriod) {
            return TimeComparisonFilterOptions.getByCode(TimeComparisonOptionCode.AdjoiningPeriod).withFilter(comparisonFilter);
        }

        const match = TimeComparisonFilterOptions.VALUES.find(opt => opt.filter.typeCode === comparisonFilter.typeCode && opt.filter.index === comparisonFilter.index);

        if (match) {
            return match.withFilter(comparisonFilter);
        }

        // we assume that the comparionFilter is in the right format and therefore it can only be OffsetByXPeriods
        if (comparisonFilter.typeCode === TimeTypeCode.Months && comparisonFilter.index === -1) {
            return TimeComparisonFilterOptions.getByCode(ExtendedComparisonCode.MONTH_OVER_MONTH);
        }

        if (comparisonFilter.typeCode === TimeTypeCode.Weeks && comparisonFilter.index === -1) {
            return TimeComparisonFilterOptions.getByCode(ExtendedComparisonCode.WEEK_OVER_WEEK);
        }

        return TimeComparisonFilterOptions.getByCode(TimeComparisonOptionCode.OffsetByXPeriods).withFilter(comparisonFilter);

    }

    public getTimePeriod(filter: TimeFilter, referenceFromDate?: Date): DateSpan {

        if (!filter) {
            return {from: null, to: null};
        }

        const today = referenceFromDate || this.timeService.getUTCToday();

        const refDateFrom = this.timeService.getDateByDayOffset(today, filter.from.relativeToYesterday ? -1 : 0);
        const refDateTo = this.timeService.getDateByDayOffset(today, filter.to.relativeToYesterday ? -1 : 0);

        let from = null;

        if (!filter.from.date) {
            if (filter.from.index !== null && filter.from.index !== undefined) {
                switch (filter.from.typeCode) {
                    case 'years':
                        from = this.timeService.getDateByYearOffset(this.timeService.getYearStartOfDate(refDateFrom), filter.from.index);
                        break;
                    case 'quarters':
                        from = this.timeService.getDateByMonthOffset(this.timeService.getQuarterStartOfDate(refDateFrom), filter.from.index * 3);
                        break;
                    case 'months':
                        from = this.timeService.getDateByMonthOffset(this.timeService.getMonthStartOfDate(refDateFrom), filter.from.index);
                        break;
                    case 'weeks':
                        from = this.timeService.getDateByDayOffset(this.timeService.getWeekStartOfDate(refDateFrom), filter.from.index * 7);
                        break;
                    case 'days':
                        from = this.timeService.getDateByDayOffset(refDateFrom, filter.from.index);
                        break;
                    case 'fiscalYears':
                        from = this.timeService.getDateByYearOffset(this.timeService.getCurrentFiscalYearByRef(refDateFrom), filter.from.index);
                        break;

                }
            }
        } else {
            if (!this.timeService.isValidDate(filter.from.date) && typeof filter.from.date === 'number') {
                filter.from.date = new Date(filter.from.date);
            } else if (typeof filter.from.date === 'string') {
                filter.from.date = new Date(filter.from.date);
            }
            from = filter.from.date;
        }

        let to = null;

        if (filter.to.date && !this.timeService.isValidDate(filter.to.date)) {
            filter.to.date = new Date(filter.to.date);
        } else if (typeof filter.to.date === 'string') {
            filter.to.date = new Date(filter.to.date);
        }

        if (!filter.to.date) {
            if (filter.to.index !== null && filter.to.index !== undefined) {
                switch (filter.to.typeCode) {
                    case 'years':
                        to = this.timeService.getDateByYearOffset(this.timeService.getYearStartOfDate(refDateTo), filter.to.index + 1);
                        to = this.timeService.getDateByDayOffset(to, -1);
                        break;
                    case 'quarters':
                        to = this.timeService.getDateByMonthOffset(this.timeService.getQuarterStartOfDate(refDateTo), (filter.to.index + 1) * 3);
                        to = this.timeService.getDateByDayOffset(to, -1);
                        break;
                    case 'months':
                        to = this.timeService.getDateByMonthOffset(this.timeService.getMonthStartOfDate(refDateTo), filter.to.index + 1);
                        to = this.timeService.getDateByDayOffset(to, -1);
                        break;
                    case 'weeks':
                        to = this.timeService.getDateByDayOffset(this.timeService.getWeekStartOfDate(refDateTo), (filter.to.index + 1) * 7 - 1);
                        break;
                    case 'days':
                        to = this.timeService.getDateByDayOffset(refDateTo, filter.to.index);
                        break;
                    case 'fiscalYears':
                        to = this.timeService.getDateByYearOffset(this.timeService.getCurrentFiscalYearByRef(refDateTo), filter.to.index + 1);
                        to = this.timeService.getDateByDayOffset(to, -1);
                        break;
                }
            }
        } else {
            if (!this.timeService.isValidDate(filter.to.date) && typeof filter.to.date === 'number') {
                filter.to.date = new Date(filter.to.date);
            }
            to = filter.to.date;
        }

        return { from: from, to: to };
    }

    public getComparisonPeriod(timeFilter: TimeFilter, comparisonFilter: TimeComparisonFilter, referenceFromDate?: Date): DateSpan {
        if (comparisonFilter == null) {
            return null;
        }
        const timeFilterSpan = this.getTimePeriod(timeFilter, referenceFromDate);
        const filter = {
            from: {
                index: comparisonFilter.index,
                typeCode: comparisonFilter.typeCode,
                date: new Date(comparisonFilter.fromDate)
            },
            to: {
                index: comparisonFilter.index,
                typeCode: comparisonFilter.typeCode,
                date: new Date(comparisonFilter.toDate)
            }
        };

        if (!this.timeService.isValidDate(filter.from.date)) {
            filter.from.date = null;
        }
        if (!this.timeService.isValidDate(filter.to.date)) {
            filter.to.date = null;
        }

        let from = null;

        if (!filter.from.date) {
            if (filter.from.index !== null && filter.from.index !== undefined) {
                switch (filter.from.typeCode) {
                    case 'years':
                        from = this.timeService.getDateByYearOffset(timeFilterSpan.from, filter.from.index);
                        break;
                    case 'quarters':
                        from = this.timeService.getDateByQuarterOffset(timeFilterSpan.from, filter.from.index * 3);
                        break;
                    case 'months':
                        from = this.timeService.getDateByMonthOffset(timeFilterSpan.from, filter.from.index);
                        break;
                    case 'weeks':
                        from = this.timeService.getDateByDayOffset(timeFilterSpan.from, filter.from.index * 7);
                        break;
                    case 'days':
                        from = this.timeService.getDateByDayOffset(timeFilterSpan.from, filter.from.index);
                        break;
                    case 'fiscalYears':
                        from = this.timeService.getDateByYearOffset(this.timeService.getCurrentFiscalYearByRef(timeFilterSpan.from), filter.from.index);
                        break;
                }
            }
        } else {
            from = filter.from.date;
        }

        let to = null;

        if (filter.to.date && !this.timeService.isValidDate(filter.to.date)) {
            filter.to.date = new Date(filter.to.date);
        }

        if (!filter.to.date) {
            if (filter.to.index !== null && filter.to.index !== undefined) {
                switch (filter.to.typeCode) {
                    case 'years':
                        to = this.timeService.getDateByYearOffset(timeFilterSpan.to, filter.to.index);
                        break;
                    case 'quarters':
                        to = this.timeService.getDateByQuarterOffset(timeFilterSpan.to, (filter.to.index + 1) * 3);
                        break;
                    case 'months':
                        to = this.timeService.getDateByMonthOffset(timeFilterSpan.to, filter.to.index);
                        break;
                    case 'weeks':
                        to = this.timeService.getDateByDayOffset(timeFilterSpan.to, (filter.to.index) * 7);
                        break;
                    case 'days':
                        to = this.timeService.getDateByDayOffset(timeFilterSpan.to, filter.to.index);
                        break;
                    case 'fiscalYears':
                        to = this.timeService.getDateByYearOffset(this.timeService.getCurrentFiscalYearByRef(timeFilterSpan.to), filter.to.index);
                        break;
                }
            }
        } else {
            to = filter.to.date;
        }

        return { from: from, to: to };
    }

    public calculateAdjoiningPeriodDayCount(timeFilter: TimeFilter): number {
        const timeFilterSpan = this.getTimePeriod(timeFilter);
        const timeFilterDiffInDays = this.timeService.getDiffInDays(timeFilterSpan.from, timeFilterSpan.to);
        const adjoiningPeriodIndex = -timeFilterDiffInDays - 1;
        return adjoiningPeriodIndex;
    }

}
