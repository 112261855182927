import { HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

export class MnbBusyStatus {

    constructor(code: number = -1) {
        this.code = code;
    }

    public get isDone(): boolean {
        return this.code === 1;
    }
    public get isRunning(): boolean {
        return this.code === -1;
    }

    static DONE = new MnbBusyStatus(1);
    static RUNNING = new MnbBusyStatus(-1);


    message: string;
    code: number;
    readonly done$ = new BehaviorSubject(false);

    static ERROR = (error?: HttpErrorResponse) => {
        const result = new MnbBusyStatus();
        result.httpError(error);
        return result;
    }

    reset() {
        this.code = -1;
        this.message = null;
        this.done$.next(false);
    }

    done() {
        this.code = 1;
        this.message = null;
        this.done$.next(true);
    }

    error(message: string) {
        this.code = 0;
        this.message = message;
    }

    httpError(error: HttpErrorResponse, defaultMessage: string = 'SOMETHING WENT WRONG') {
        // Leaving this error text hardcoded because it should rarely be used anyway
        this.code = 0;
        if (!!error && error.status && error.statusText) {
            this.message = `${error.status} - ${error.statusText}`;
        } else {
            this.message = defaultMessage;
        }
    }
}
