import { Pipe, PipeTransform } from '@angular/core';
import { MnbModelService } from '@shared-lib/modules/model/services/mnb-model.service';
import { ModelAttribute } from '@shared-lib/modules/model/mnb-model.model';

@Pipe({
    name: 'mnbAttribute',
    pure: true
})
export class MnbAttributePipe implements PipeTransform {

    constructor(private modelService: MnbModelService) {}

    transform(attributeCode: string): Promise<ModelAttribute> {
        if (!attributeCode) {
            return Promise.resolve(null);
        }
        return this.modelService.getAttribute(attributeCode).then(attribute => attribute);

    }
}
