import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import {
    Report,
    ReportData,
    ReportExperienceGroupDataRow,
    ReportSettings,
    TableSortingState,
} from '@shared-lib/modules/data/model/mnb-data-reports.model';
import { ModelAttribute, ModelMeasure } from '@shared-lib/modules/model/mnb-model.model';
import { MnbModelService } from '@shared-lib/modules/model/services/mnb-model.service';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'mnb-reports-experience-group-display',
    exportAs: 'displayComponent',
    templateUrl: './mnb-reports-experience-group-display.component.html',
    styleUrls: ['./mnb-reports-experience-group-display.component.less']
})
export class MnbReportsExperienceGroupDisplayComponent implements OnInit, OnDestroy {

    private destroy$ = new Subject<void>();

    @Input() set report(report: Report) {
        this.report$.next(report);
    }
    @Input() set viewSettings(viewSettings: ReportSettings) {
        this.viewSettings$.next(viewSettings);
    }
    @Input() set data(data: ReportData) {
        this.data$.next(data);
    }

    @Output() viewSettingsEmitter: EventEmitter<ReportSettings> = new EventEmitter();
    @Output() experienceGroupSelectionChanged: EventEmitter<string> = new EventEmitter();

    // state
    private report$ = new BehaviorSubject<Report>(null);
    private viewSettings$ = new BehaviorSubject<ReportSettings>(null);
    private data$ = new BehaviorSubject<ReportData>(null);
    private breakdownAttribute$ = new BehaviorSubject<ModelAttribute>(null);
    private subBreakdownAttribute$ = new BehaviorSubject<ModelAttribute>(null);
    public availableMeasures$ = new BehaviorSubject<ModelMeasure[]>([]);

    // derived state
    private measureModels$: Observable<MeasureModel[]>;
    protected availableExperienceGroups$: Observable<string[]>;
    private selectedMeasures$: Observable<ModelMeasure[]>;
    private hasSubRows$: Observable<boolean>;

    public model$: Observable<ExperienceGroupDisplayModel>;

    constructor(private modelService: MnbModelService) { }

    ngOnInit() {
        this.availableExperienceGroups$ = this.data$.pipe(map(data => {
            return data ? data.experienceGroup.availableExperienceGroups : [];
        }));

        // validate selectedExperienceGroup
        combineLatest([this.availableExperienceGroups$, this.viewSettings$]).pipe(
            takeUntil(this.destroy$)
        ).subscribe(([availableExperienceGroups, viewSettings]) => {
            if (availableExperienceGroups.length > 0) {
                const isUnset = !viewSettings.experienceGroup.selectedExperienceGroup;
                const isInvalid = !availableExperienceGroups.includes(viewSettings.experienceGroup.selectedExperienceGroup);
                if (isUnset || isInvalid) {
                    this.experienceGroupSelectionChanged.emit(availableExperienceGroups[0]);
                }
            }
        });

        this.selectedMeasures$ = combineLatest([this.viewSettings$, this.availableMeasures$]).pipe(
            map(([viewSettings, availableMeasures]) => {
                if (viewSettings && viewSettings.experienceGroup && viewSettings.experienceGroup.selectedMeasureCodes) {
                    const selectedMeasures: ModelMeasure[] = [];
                    availableMeasures.forEach(measure => {
                        if (viewSettings.experienceGroup.selectedMeasureCodes.includes(measure.code)) {
                            selectedMeasures.push(measure);
                        }
                    });
                    return selectedMeasures;
                }
                return [];
            })
        );

        this.measureModels$ = combineLatest([
            this.viewSettings$,
            this.selectedMeasures$,
        ]).pipe(
            map(([viewSettings, selectedMeasures]) =>
                selectedMeasures.map(measure => {
                    // get sortDirection
                    let sortDirection: SortDirection;
                    if (viewSettings.experienceGroup.sort.measureCode === measure.code) {
                        sortDirection = viewSettings.experienceGroup.sort.directionCode === 'ASC' ? 'ASC' : 'DESC';
                    } else {
                        sortDirection = null;
                    }
                    return { measure, sortDirection };
                })
            )
        );

        // validate sort
        combineLatest([this.viewSettings$, this.selectedMeasures$]).pipe(
            takeUntil(this.destroy$)
        ).subscribe(
            ([viewSettings, selectedMeasures]) => {
                if (!viewSettings || !selectedMeasures) {
                    return;
                }

                const measuresAreSelected = viewSettings && viewSettings.experienceGroup && viewSettings.experienceGroup.selectedMeasureCodes && viewSettings.experienceGroup.selectedMeasureCodes.length > 0;
                if (!measuresAreSelected) {
                    return;
                }

                const sortIsValid = measuresAreSelected && viewSettings.experienceGroup.sort && viewSettings.experienceGroup.sort.measureCode && viewSettings.experienceGroup.selectedMeasureCodes.includes(viewSettings.experienceGroup.sort.measureCode);
                if (sortIsValid) {
                    return;
                }

                // if invalid sorting is given, apply initial sorting
                const newViewSettings = new ReportSettings();
                newViewSettings.experienceGroup = {
                    sort: {
                        measureCode: viewSettings.experienceGroup.selectedMeasureCodes[0],
                        directionCode: 'DESC',
                    },
                };
                this.viewSettingsEmitter.emit(newViewSettings);
            });

        this.report$.pipe(takeUntil(this.destroy$)).subscribe((report) => {
            if (!report) {
                return;
            }
            this.modelService
                .getAttribute(report.settings.experienceGroup.breakdownAttributeCode)
                .then((result) => this.breakdownAttribute$.next(result));

            if (!!report.settings.experienceGroup.subBreakdownAttributeCode) {
                this.modelService
                    .getAttribute(report.settings.experienceGroup.subBreakdownAttributeCode)
                    .then((result) => this.subBreakdownAttribute$.next(result));
            }

            const measurePromises: Promise<ModelMeasure>[] = report.settings.experienceGroup.measureCodes.map(
                (measureCode) => {
                    return this.modelService.getMeasure(measureCode);
                }
            );
            Promise.all(measurePromises).then((result) => this.availableMeasures$.next(result));
        });

        this.hasSubRows$ = this.data$.pipe(
            map(data => {
                if (data && data.experienceGroup) {
                    return Object.keys(data.experienceGroup.subRowsMap).length > 0;
                } else {
                    return false;
                }
            }
        ));

        this.model$ = combineLatest([
            this.viewSettings$,
            this.breakdownAttribute$,
            this.subBreakdownAttribute$,
            this.data$,
            this.hasSubRows$,
            this.measureModels$
        ]).pipe(
            map(([viewSettings, breakdownAttribute, subBreakdownAttribute, data, hasSubRows, measureModels]) => {
                if (!data) {
                    return {
                        restricted: true,
                    };
                }
                const selectedExperienceGroup = viewSettings.experienceGroup.selectedExperienceGroup;
                const hasComparison = !!viewSettings.experienceGroup.comparisonFilter;
                const rows = data.experienceGroup.rows;
                const subRowsMap = data.experienceGroup.subRowsMap;
                const totalsRow = data.experienceGroup.totalsRow;
                return {
                    restricted: false,
                    hasComparison,
                    hasSubRows,
                    selectedExperienceGroup,
                    breakdownAttribute,
                    subBreakdownAttribute,
                    measureModels,
                    totalsRow,
                    rows,
                    subRowsMap,
                };
            })
        );
    }

    public toggleMeasureSort(measureCode: string) {
        const oldSort: TableSortingState = this.viewSettings$.getValue().experienceGroup.sort;
        let newSort: TableSortingState;
        if (oldSort.measureCode === measureCode && oldSort.directionCode === 'DESC') {
            // sort state DESC -> ASC
            newSort = {
                measureCode: measureCode,
                directionCode: 'ASC',
            };
        } else {
            // sort ASC -> DESC
            newSort = {
                measureCode: measureCode,
                directionCode: 'DESC',
            };
        }

        const viewSettings = new ReportSettings();
        viewSettings.experienceGroup = {
            sort: newSort,
        };
        this.viewSettingsEmitter.emit(viewSettings);
    }

    public onExperienceGroupSelectionChanged(selectedExperienceGroup: string) {
        this.experienceGroupSelectionChanged.emit(selectedExperienceGroup);
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}

export type ExperienceGroupDisplayModel = {
    restricted?: boolean;
    hasComparison?: boolean;
    hasSubRows?: boolean;
    selectedExperienceGroup?: string;
    breakdownAttribute?: ModelAttribute;
    subBreakdownAttribute?: ModelAttribute;
    measureModels?: MeasureModel[];
    totalsRow?: ReportExperienceGroupDataRow;
    rows?: ReportExperienceGroupDataRow[];
    subRowsMap?: {[breakdownAttributeValue: string]: ReportExperienceGroupDataRow[]};
};

export type SortDirection = 'DESC' | 'ASC';

export type MeasureModel = {
    measure: ModelMeasure;
    sortDirection: SortDirection;
};
