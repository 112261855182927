import { TimeComparisonOptionCode, ExtendedComparisonCode } from '@shared-lib/modules/core/services/time/time.model';
import { QuerySettingsTimeFilterOptionCode } from '@shared-lib/modules/data/model/mnb-data-query.model';

export const comparisonOptionMap = new Map<QuerySettingsTimeFilterOptionCode, (TimeComparisonOptionCode | ExtendedComparisonCode)[]>();

comparisonOptionMap.set(QuerySettingsTimeFilterOptionCode.TODAY, [
    TimeComparisonOptionCode.DateRange,
    TimeComparisonOptionCode.AdjoiningPeriod,
    TimeComparisonOptionCode.YearOverYearByDate
]);

comparisonOptionMap.set(QuerySettingsTimeFilterOptionCode.YESTERDAY, [
    TimeComparisonOptionCode.DateRange,
    TimeComparisonOptionCode.AdjoiningPeriod,
    TimeComparisonOptionCode.YearOverYearByDate
]);

comparisonOptionMap.set(QuerySettingsTimeFilterOptionCode.LAST_7_DAYS, [
    TimeComparisonOptionCode.DateRange,
    TimeComparisonOptionCode.AdjoiningPeriod,
    TimeComparisonOptionCode.YearOverYearByDate,
    TimeComparisonOptionCode.YearOverYearByWeekNumber
]);

comparisonOptionMap.set(QuerySettingsTimeFilterOptionCode.LAST_30_DAYS, [
    TimeComparisonOptionCode.DateRange,
    TimeComparisonOptionCode.AdjoiningPeriod,
    TimeComparisonOptionCode.YearOverYearByDate,
    TimeComparisonOptionCode.YearOverYearByWeekNumber
]);

comparisonOptionMap.set(QuerySettingsTimeFilterOptionCode.LAST_365_DAYS, [
    TimeComparisonOptionCode.DateRange,
    TimeComparisonOptionCode.AdjoiningPeriod,
    TimeComparisonOptionCode.YearOverYearByDate,
    TimeComparisonOptionCode.YearOverYearByWeekNumber
]);

comparisonOptionMap.set(QuerySettingsTimeFilterOptionCode.THIS_WEEK_TO_DATE, [
    TimeComparisonOptionCode.DateRange,
    TimeComparisonOptionCode.YearOverYearByWeekNumber,
    ExtendedComparisonCode.WEEK_OVER_WEEK,
]);

comparisonOptionMap.set(QuerySettingsTimeFilterOptionCode.LAST_WEEK, [
    TimeComparisonOptionCode.DateRange,
    TimeComparisonOptionCode.AdjoiningPeriod,
    TimeComparisonOptionCode.YearOverYearByWeekNumber
]);

comparisonOptionMap.set(QuerySettingsTimeFilterOptionCode.LAST_52_WEEKS, [
    TimeComparisonOptionCode.DateRange,
    TimeComparisonOptionCode.AdjoiningPeriod,
    TimeComparisonOptionCode.YearOverYearByWeekNumber
]);


comparisonOptionMap.set(QuerySettingsTimeFilterOptionCode.THIS_MONTH_TO_DATE, [
    TimeComparisonOptionCode.DateRange,
    TimeComparisonOptionCode.YearOverYearByDate,
    TimeComparisonOptionCode.YearOverYearByWeekNumber,
    ExtendedComparisonCode.MONTH_OVER_MONTH,
]);

comparisonOptionMap.set(QuerySettingsTimeFilterOptionCode.LAST_MONTH, [
    TimeComparisonOptionCode.DateRange,
    TimeComparisonOptionCode.AdjoiningPeriod,
    TimeComparisonOptionCode.YearOverYearByDate,
    TimeComparisonOptionCode.YearOverYearByWeekNumber,
]);

comparisonOptionMap.set(QuerySettingsTimeFilterOptionCode.LAST_12_MONTHS, [
    TimeComparisonOptionCode.DateRange,
    TimeComparisonOptionCode.AdjoiningPeriod,
    TimeComparisonOptionCode.YearOverYearByDate,
    TimeComparisonOptionCode.YearOverYearByWeekNumber,
]);

comparisonOptionMap.set(QuerySettingsTimeFilterOptionCode.LAST_QUARTER, [
    TimeComparisonOptionCode.DateRange,
    TimeComparisonOptionCode.AdjoiningPeriod,
    TimeComparisonOptionCode.YearOverYearByWeekNumber,
]);

comparisonOptionMap.set(QuerySettingsTimeFilterOptionCode.LAST_3_QUARTERS, [
    TimeComparisonOptionCode.DateRange,
    TimeComparisonOptionCode.AdjoiningPeriod,
    TimeComparisonOptionCode.YearOverYearByWeekNumber,
]);

comparisonOptionMap.set(QuerySettingsTimeFilterOptionCode.THIS_QUARTER_TO_DATE, [
    TimeComparisonOptionCode.DateRange,
    ExtendedComparisonCode.MONTH_OVER_MONTH,
]);

comparisonOptionMap.set(QuerySettingsTimeFilterOptionCode.THIS_YEAR_TO_DATE, [
    TimeComparisonOptionCode.DateRange,
    TimeComparisonOptionCode.YearOverYearByDate,
]);

comparisonOptionMap.set(QuerySettingsTimeFilterOptionCode.LAST_YEAR, [
    TimeComparisonOptionCode.DateRange,
    TimeComparisonOptionCode.AdjoiningPeriod,
    TimeComparisonOptionCode.YearOverYearByDate,
]);

comparisonOptionMap.set(QuerySettingsTimeFilterOptionCode.THIS_FISCAL_YEAR_TO_DATE, [
    TimeComparisonOptionCode.DateRange,
    TimeComparisonOptionCode.YearOverYearByDate,
]);

comparisonOptionMap.set(QuerySettingsTimeFilterOptionCode.LAST_FISCAL_YEAR, [
    TimeComparisonOptionCode.DateRange,
    TimeComparisonOptionCode.AdjoiningPeriod,
    TimeComparisonOptionCode.YearOverYearByDate,
]);

comparisonOptionMap.set(QuerySettingsTimeFilterOptionCode.CUSTOM, [
    TimeComparisonOptionCode.DateRange,
    TimeComparisonOptionCode.AdjoiningPeriod,
    TimeComparisonOptionCode.YearOverYearByDate,
    TimeComparisonOptionCode.YearOverYearByWeekNumber,
]);
