import { Component, ComponentFactoryResolver, Directive, DoCheck, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { MnbBusyStatus } from '../../model/mnb-core-busy-status.model';

@Directive({
    selector: '[mnbBusyIndicator]'
})
export class MnbBusyIndicatorDirective implements DoCheck {

    @Input('mnbBusyIndicator') status: MnbBusyStatus;
    @Input('mnbBusyIndicatorHeight') height: number;

    private currentStatus = -99;

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private componentFactoryResolver: ComponentFactoryResolver
    ) { }

    ngDoCheck() {
        const status = this.status || MnbBusyStatus.RUNNING;

        if (this.currentStatus !== status.code) {
            this.viewContainer.clear();
            if (status.code === 1) {
                // render if not busy
                this.viewContainer.createEmbeddedView(this.templateRef);
            } else {
                // render busy inner
                const componentRef = this.viewContainer.createComponent(this.componentFactoryResolver.resolveComponentFactory(MnbBusyIndicatorComponent));

                componentRef.instance.status = status;
                componentRef.instance.height = this.height;
            }
            this.currentStatus = status.code;
        }
    }
}

@Component({
    selector: 'mnb-busy-indicator',
    template:
        `<div class="tile" [style.height.px]="height">
    <div class="mnb-loading-spinner">
        <div *ngIf="status.code === -1" class="load-icon">
        </div>
        <div *ngIf="status.code === 0" class="load-text">{{ status.message | translate }}</div>
    </div>
  </div>`,
    host: { 'class': 'mnb-busy-indicator-container' }
})
export class MnbBusyIndicatorComponent {
    status: MnbBusyStatus;
    height: number;
}
