import { Directive, ElementRef, HostListener, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, takeUntil, withLatestFrom } from 'rxjs/operators';

@Directive({
  selector: '[mnbDateInput]',
  exportAs: 'mnbDateInput',
})
export class DateInputMaskDirective implements OnChanges, OnDestroy {
  private destroy$ = new Subject<void>();

  @Input() selectedDate: Date = new Date(0);

  dateStringMask = 'YYYY-MM-DD';
  caretIndex$ = new BehaviorSubject(0);
  currentDateString$ = new BehaviorSubject<string>(this.dateStringMask);

  currentDate$ = this.currentDateString$.pipe(map((dateString) => this.stringToDate(dateString)));

  hasChanged$: Observable<boolean> = this.currentDateString$.pipe(
    map((currentDateString) => !this.dateIsEqual(this.stringToDate(currentDateString), this.selectedDate))
  );

  isValid$: Observable<boolean> = combineLatest([this.currentDateString$, this.currentDate$]).pipe(
    map(([currentDateString, currentDate]) => this.validateDate(currentDateString, currentDate))
  );

  selectDateFromInput$ = new Subject<void>();
  @Output() selectDate: Observable<Date> = this.selectDateFromInput$.pipe(
    withLatestFrom(this.isValid$, this.hasChanged$, this.currentDate$),
    filter(([_, isValid, hasChanged, __]) => isValid && hasChanged),
    map(([_, __, ___, currentDate]) => currentDate)
  );
  public selectDateFromInput() {
    this.selectDateFromInput$.next();
  }

  private resetInputString(): void {
    this.currentDateString$.next(this.dateToString(this.selectedDate));
  }

  constructor(private elementRef: ElementRef) {
    combineLatest([this.currentDateString$, this.caretIndex$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.refreshInputData());
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.resetInputString();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  @HostListener('keydown.enter')
  onEnterKey() {
    this.selectDateFromInput();
  }

  @HostListener('keydown.escape')
  onEscapeKey() {
    this.resetInputString();
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    const dateString: string = event.clipboardData.getData('text/plain');

    // validate
    const date = this.stringToDate(dateString);
    if (this.validateDate(dateString, date)) {
      this.currentDateString$.next(this.dateToString(date));
      this.selectDateFromInput();
    }
  }

  @HostListener('input', ['$event'])
  onInput(event) { // type: InputEvent
    let index = event.target.selectionStart;

    if (event.inputType === 'insertText') {
      const data = event.data;

      if (!this.isSingleDigitNumber(data)) {
        this.refreshInputData();
        return;
      }

      if (index > 10) {
        this.refreshInputData();
        return;
      }

      // move caret when typing in front of '-'
      if (index === 5 || index === 8) {
        index += 1;
      }

      const newDateString = this.replaceCharAtPosition(this.currentDateString$.getValue(), index, data);
      this.currentDateString$.next(newDateString.substring(0, 10));

      // caret skip '-' after typing
      if (index === 4 || index === 7) {
        index += 1;
      }

      this.caretIndex$.next(index);
    }

    if (event.inputType === 'deleteContentBackward') {
      if (index < 0) {
        this.caretIndex$.next(0);
        return;
      }

      // index = index - 1
      if (index === 4 || index === 7) {
        index -= 1;
      }
      const char = this.dateStringMask[index];
      const newDateString = this.replaceCharAtPosition(this.currentDateString$.getValue(), index + 1, char);
      this.currentDateString$.next(newDateString.substring(0, 10));

      // caret skip '-' after typing
      if (index === 5 || index === 8) {
        index -= 1;
      }
      this.caretIndex$.next(index);
    }

    this.refreshInputData();

  }

  private refreshInputData() {
    this.elementRef.nativeElement.value = this.currentDateString$.getValue();
    this.elementRef.nativeElement.selectionStart = this.caretIndex$.getValue();
    this.elementRef.nativeElement.selectionEnd = this.caretIndex$.getValue();
  }

  private replaceCharAtPosition(inString: string, index: number, char: string) {
    return inString.substring(0, index - 1) + char + inString.substring(index);
  }

  private isSingleDigitNumber(inString: string) {
    return /^[0-9]$/.test(inString);
  }

  private stringToDate(dateString: string): Date {
    const parts = dateString.replace(/[^0-9\-]/g, '').split('-');
    const yearMonthDate: [number, number, number] = [parseInt(parts[0]), parseInt(parts[1]) - 1, parseInt(parts[2])];
    return new Date(Date.UTC(...yearMonthDate));
  }

  private dateToString(date: Date): string {
    const segments = [
      date.getUTCFullYear().toString().padStart(4, '0'),
      (date.getUTCMonth() + 1).toString().padStart(2, '0'),
      date.getUTCDate().toString().padStart(2, '0'),
    ];
    return segments.join('-');
  }

  private validateDate(dateString: string, date: Date): boolean {
    if (!date || date.toString() === 'Invalid Date') {
      return false;
    }
    const parts = dateString.split('-');
    return (
      parseInt(parts[0]) === date.getUTCFullYear() &&
      parseInt(parts[1]) === date.getUTCMonth() + 1 &&
      parseInt(parts[2]) === date.getUTCDate()
    );
  }

  private dateIsEqual(dateA: Date, dateB: Date): boolean {
    return (
      dateA.getUTCFullYear() === dateB.getUTCFullYear() &&
      dateA.getUTCMonth() === dateB.getUTCMonth() &&
      dateA.getUTCDate() === dateB.getUTCDate()
    );
  }
}
