import { Pipe, PipeTransform } from '@angular/core';
import { MnbModelService } from '@shared-lib/modules/model/services/mnb-model.service';
import { ModelAttribute, ModelMeasure } from '@shared-lib/modules/model/mnb-model.model';

@Pipe({
    name: 'mnbMeasure',
    pure: true
})
export class MnbMeasurePipe implements PipeTransform {

    constructor(private modelService: MnbModelService) {}

    transform(measureCode: string): Promise<ModelMeasure> {
        if (!measureCode) {
            return Promise.resolve(null);
        }
        return this.modelService.getMeasure(measureCode).then(measure => measure);

    }
}
