import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ChangeDetectionStrategy } from '@angular/core';
import { MnbDropdownInputGroup, MnbDropdownInputGroupConfig } from '@shared-lib/modules/core/components/dropdown-input/mnb-dropdown-input.component';
import { DateSpan } from '@shared-lib/modules/core/model/mnb-time.model';
import { MnbDateSpanPipe } from '@shared-lib/modules/core/pipes/date-span.pipe';
import { TimeFilterService } from '@shared-lib/modules/core/services/time/time-filter.service';
import { ExtendedComparisonCode, TimeComparisonFilter, TimeComparisonFilterOptions, TimeComparisonOption, TimeComparisonOptionCode, TimeFilter, TimeFilterOption, TimeFilterOptions } from '@shared-lib/modules/core/services/time/time.model';
import { deepCopy } from '@shared-lib/modules/core/utils/deep-copy.util';
import { QuerySettingsComparisonFilter, QuerySettingsTimeFilter, QuerySettingsTimeFilterOptionCode, TimeTypeCode } from '@shared-lib/modules/data/model/mnb-data-query.model';
import deepEqual from 'deep-equal';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map, tap, takeUntil } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { comparisonOptionMap } from './mnb-quick-time-filter.options';

@Component({
    selector: 'mnb-quick-time-filter',
    templateUrl: './mnb-quick-time-filter.component.html',
    styleUrls: ['./mnb-quick-time-filter.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MnbQuickTimeFilterComponent implements OnDestroy, OnInit {

    private destroy$: Subject<void> = new Subject();

    // main state (behaviour subjects)
    public isOpen$ = new BehaviorSubject(false);
    public comparisonPeriodEnabled$ = new BehaviorSubject(false);
    public timeFilter$: BehaviorSubject<QuerySettingsTimeFilter> = new BehaviorSubject(null);
    public comparisonTimeFilter$: BehaviorSubject<QuerySettingsComparisonFilter> = new BehaviorSubject(null);
    private originalTimeFilter: QuerySettingsTimeFilter;
    private originalComparisonTimeFilter: QuerySettingsComparisonFilter;

    // derived state (observables)
    public showResetButton$: Observable<boolean>;
    public comparisonFilterCode$: Observable<TimeComparisonOptionCode | ExtendedComparisonCode>;
    public comparisonFilterOptions$: Observable<ExtendedTimeComparisonOption[]>;
    public dateSpan$: Observable<DateSpan>;
    public comparisonDateSpan$: Observable<DateSpan>;
    public isCustomSelected$: Observable<boolean>;
    public isComparisonCustomSelected$: Observable<boolean>;
    public title$: Observable<string>;
    public description$: Observable<string>;

    // non reactive state
    public timeFilterOptions: TimeFilterOption[];
    public groupConfig: MnbDropdownInputGroupConfig;

    @Input() set timeFilters(value: MnbQuickTimeFilterValue) {
        this.timeFilter$.next(value.timeFilter);
        this.comparisonTimeFilter$.next(value.comparisonFilter);
    }

    @Input() set originalTimeFilters(value: MnbQuickTimeFilterValue) {
        this.originalTimeFilter = value.timeFilter;
        this.originalComparisonTimeFilter = value.comparisonFilter;
    }

    @Input() private allowToday = false;
    @Input() public enableComparisonToggle = true;
    @Input() private enableComparison = true;

    @Output() valueChange = new EventEmitter<MnbQuickTimeFilterValue>();

    constructor(
        private datePipe: MnbDateSpanPipe,
        private timeFilterService: TimeFilterService,
    ) {
        this.showResetButton$ = combineLatest([this.timeFilter$, this.comparisonTimeFilter$]).pipe(
            map(([timeFilter, comparisonTimeFilter]) => {
                if (this.originalTimeFilter && this.originalComparisonTimeFilter) {
                    return !deepEqual(timeFilter, this.originalTimeFilter) || !deepEqual(comparisonTimeFilter, this.originalComparisonTimeFilter);
                }
                if (this.originalTimeFilter) {
                    return !deepEqual(timeFilter, this.originalTimeFilter);
                }
                return false;
            }));

        this.dateSpan$ = this.timeFilter$.pipe(
            map(timeFilter => this.timeFilterService.getTimePeriod(timeFilter)));

        this.comparisonDateSpan$ = combineLatest([this.timeFilter$, this.comparisonTimeFilter$]).pipe(
            map(([timeFilter, comparisonTimeFilter]) => this.timeFilterService.getComparisonPeriod(timeFilter, comparisonTimeFilter as TimeComparisonFilter)));

        this.isCustomSelected$ = this.timeFilter$.pipe(
            map(timeFilter => timeFilter.optionCode === QuerySettingsTimeFilterOptionCode.CUSTOM));

        this.isComparisonCustomSelected$ = this.comparisonTimeFilter$.pipe(
            map(comparisonTimeFilter => {
                const comparisonTimeFilterOption: TimeComparisonOption = deepCopy(this.timeFilterService.getComparisonOption(comparisonTimeFilter as TimeComparisonFilter));
                if (isNullOrUndefined(comparisonTimeFilterOption)) {
                    return false;
                }
                return comparisonTimeFilterOption.code === TimeComparisonOptionCode.DateRange;
            }));

        this.title$ = this.timeFilter$.pipe(
            map(timeFilter => {
                const timeFilterOption = TimeFilterOptions.getByCode(timeFilter.optionCode);
                return timeFilterOption.label;
            }));

        this.description$ = this.timeFilter$.pipe(
            map(timeFilter => {
                const dateSpan = this.timeFilterService.getTimePeriod(timeFilter);
                return ' (' + this.datePipe.transform(dateSpan, { shorten: true }) + ')';
            }));

        this.comparisonFilterCode$ = this.comparisonTimeFilter$.pipe(
            map(comparisonTimeFilter => {
                if (comparisonTimeFilter == null) {
                    return null;
                }
                const comparisonFilterOption = deepCopy(this.timeFilterService.getComparisonOption(comparisonTimeFilter as TimeComparisonFilter));
                return this.getComparisonCode(comparisonFilterOption);
            }));

        this.comparisonFilterOptions$ = this.timeFilter$.pipe(
            map(timeFilter => this.getComparisonTimeFilterOptions(timeFilter.optionCode as QuerySettingsTimeFilterOptionCode)));

        this.timeFilterOptions = this.getTimeFilterOptions();

        this.groupConfig = this.createGroupConfig(this.timeFilterService.hasFiscalYearConfigured());

    }

    ngOnInit(): void {
        if (this.enableComparison) {
            this.comparisonPeriodEnabled$.next(true);
        }

        // validate comparison option with available comparison options
        combineLatest([this.comparisonPeriodEnabled$, this.comparisonFilterCode$, this.comparisonFilterOptions$]).pipe(
            tap(([comparisonPeriodEnabled, comparisonFilterCode, comparisonFilterOptions]) => {
                // comparison is disabled -> set comparisonFilter to null
                if (!comparisonPeriodEnabled) {
                    if (comparisonFilterCode == null) {
                        return;
                    }
                    const newValue: MnbQuickTimeFilterValue = {
                        timeFilter: this.timeFilter$.value,
                        comparisonFilter: null
                    };
                    this.valueChange.emit(newValue);
                    return;
                }

                // comparison is enabled, but no filter is set -> select valid comparison
                if (comparisonFilterCode == null) {
                    this.selectSecondComparisonFilterOption(comparisonFilterOptions);
                    return;
                }

                // comparison filter is set, but invalid -> select valid comparison
                const availableCodesAsSet = new Set(comparisonFilterOptions.map(v => v.comparisonCode));
                if (!availableCodesAsSet.has(comparisonFilterCode) && availableCodesAsSet.has(TimeComparisonOptionCode.AdjoiningPeriod)) {
                    this.selectSecondComparisonFilterOption(comparisonFilterOptions);
                }
            }),
            takeUntil(this.destroy$)
        ).subscribe();
    }

    private selectSecondComparisonFilterOption(comparisonFilterOptions): void {
        const secondOption = comparisonFilterOptions[1];
        const secondOptionComparisonCode = this.getComparisonCode(secondOption as TimeComparisonOption);
        this.onComparisonTimeFilterOptionChange(secondOptionComparisonCode);
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private getTimeFilterOptions(): TimeFilterOption[] {
        const customOption = TimeFilterOptions.CUSTOM_OPTION_YEAR;

        const fiscalConfigured = this.timeFilterService.hasFiscalYearConfigured();
        const filterFiscalOptionsIfFiscalYearNotConfigured = (value: TimeFilterOption) => {
            if (value.typeCode !== TimeTypeCode.FiscalYears) {
                return true;
            }
            return fiscalConfigured;
        };

        const timeFilterOptions = [...TimeFilterOptions.getAll(this.allowToday)]
            .filter(value => value.code !== QuerySettingsTimeFilterOptionCode.CUSTOM)
            .filter(filterFiscalOptionsIfFiscalYearNotConfigured)
            .concat(customOption)
            .map(value => {
                const isCustomOption = value.code === QuerySettingsTimeFilterOptionCode.CUSTOM;
                const option = {
                    ...value,
                    info: isCustomOption ? '' : '(' + this.timeFilterService.getTimeFilterDateSpanText(value.timeFilter, { shorten: true }) + ')'
                };
                this.timeFilterService.getTimeLabel(value.timeFilter, null).then(label => option.label = label);
                return option;
            });

        return timeFilterOptions;
    }

    public onClick() {
        this.isOpen$.next(!this.isOpen$.value);
    }

    public onOutsideClick() {
        this.isOpen$.next(false);
    }

    public onResetClick() {
        const out: MnbQuickTimeFilterValue = {
            timeFilter: this.originalTimeFilter,
            comparisonFilter: this.originalComparisonTimeFilter,
        };
        this.valueChange.emit(out);
    }

    onTimeFilterOptionChange(optionCode: string) {
        const timeFilterOption = TimeFilterOptions.getByCode(optionCode);
        const timeFilter = timeFilterOption.timeFilter;

        if (timeFilter.optionCode === QuerySettingsTimeFilterOptionCode.CUSTOM) {
            const oldTimeFilter = this.timeFilter$.value;
            timeFilter.from = oldTimeFilter.from;
            timeFilter.to = oldTimeFilter.to;
        }

        // update comparison filter in case of adjoining period
        const comparisonFilter = this.comparisonTimeFilter$.value;
        if (comparisonFilter != null) {
            const comparisonFilterOption = deepCopy(this.timeFilterService.getComparisonOption(comparisonFilter as TimeComparisonFilter));
            if (comparisonFilterOption.code === TimeComparisonOptionCode.AdjoiningPeriod) {
                comparisonFilter.index = this.timeFilterService.calculateAdjoiningPeriodDayCount(timeFilter);
            }
        }

        const out: MnbQuickTimeFilterValue = {
            timeFilter,
            comparisonFilter
        };
        this.valueChange.emit(out);
    }

    onComparisonTimeFilterOptionChange(optionCode: string) {
        const comparisonFilterOption = TimeComparisonFilterOptions.getByCode(optionCode as TimeComparisonOptionCode);
        const comparisonFilter = comparisonFilterOption.filter;

        if (comparisonFilterOption.code === TimeComparisonOptionCode.AdjoiningPeriod) {
            comparisonFilter.index = this.timeFilterService.calculateAdjoiningPeriodDayCount(this.timeFilter$.value);
        }

        if (comparisonFilterOption.code === TimeComparisonOptionCode.DateRange) {
            const oldTimeFilter = this.timeFilter$.value;
            const oldComparisonFilter = this.comparisonTimeFilter$.value;
            const oldDateSpan = this.timeFilterService.getComparisonPeriod(oldTimeFilter, oldComparisonFilter as TimeComparisonFilter);
            comparisonFilter.fromDate = oldDateSpan.from;
            comparisonFilter.toDate = oldDateSpan.to;
        }

        const out: MnbQuickTimeFilterValue = {
            timeFilter: this.timeFilter$.value,
            comparisonFilter
        };
        this.valueChange.emit(out);
    }

    onCustomDateChange(dateSpan: DateSpan) {
        const timeFilterOption = TimeFilterOptions.CUSTOM_OPTION_YEAR;
        const timeFilter: TimeFilter = timeFilterOption.timeFilter;
        timeFilter.from.date = dateSpan.from;
        timeFilter.to.date = dateSpan.to;

        const out: MnbQuickTimeFilterValue = {
            timeFilter,
            comparisonFilter: this.comparisonTimeFilter$.value
        };
        this.valueChange.emit(out);
    }

    onComparisonCustomDateChange(dateSpan: DateSpan) {
        const comparisonTimeFilterOption = TimeComparisonFilterOptions.DATE_RANGE;
        const comparisonTimeFilter = comparisonTimeFilterOption.filter;
        comparisonTimeFilter.fromDate = dateSpan.from;
        comparisonTimeFilter.toDate = dateSpan.to;

        const out: MnbQuickTimeFilterValue = {
            timeFilter: this.timeFilter$.value,
            comparisonFilter: comparisonTimeFilter
        };
        this.valueChange.emit(out);
    }

    onComparisonPeriodToggle(isEnabled: boolean) {
        this.comparisonPeriodEnabled$.next(isEnabled);
    }

    private getComparisonTimeFilterOptions(timeFilterOptionCode: QuerySettingsTimeFilterOptionCode): ExtendedTimeComparisonOption[] {
        const avaliableComparisonOptions: (TimeComparisonOptionCode | ExtendedComparisonCode)[] = comparisonOptionMap.get(timeFilterOptionCode);
        const timeFilterOption = TimeFilterOptions.getByCode(timeFilterOptionCode);

        const comparisonOptions: ExtendedTimeComparisonOption[] = [
            ...TimeComparisonFilterOptions.VALUES,
            ...TimeComparisonFilterOptions.EXTENDED_VALUES
        ]
            .filter(value => avaliableComparisonOptions.includes(this.getComparisonCode(value)))
            .map(value => {
                if (TimeComparisonOptionCode.AdjoiningPeriod === value.code) {
                    value.filter.index = this.timeFilterService.calculateAdjoiningPeriodDayCount(timeFilterOption.timeFilter);
                }

                let info = '(' + this.datePipe.transform(this.timeFilterService.getComparisonPeriod(timeFilterOption.timeFilter, value.filter), { shorten: true }) + ')';
                if (value.code === TimeComparisonOptionCode.DateRange) {
                    info = '';
                }

                const option: ExtendedTimeComparisonOption = {
                    ...value,
                    comparisonCode: this.getComparisonCode(value),
                    info
                };

                return option;
            });

        return comparisonOptions;
    }

    private getComparisonCode(value: TimeComparisonOption): TimeComparisonOptionCode | ExtendedComparisonCode {
        return value.extendedCode ? value.extendedCode : value.code;
    }

    private createGroupConfig(displayFiscalYearGroup: boolean): MnbDropdownInputGroupConfig {

        const result = [
            new MnbDropdownInputGroup('GENERAL.TIME.TIME_OPTIONS.CUSTOM_HEADING', null),
            new MnbDropdownInputGroup('GENERAL.TIME.LABEL.DAYS', TimeTypeCode.Days),
            new MnbDropdownInputGroup('GENERAL.TIME.LABEL.WEEKS', TimeTypeCode.Weeks),
            new MnbDropdownInputGroup('GENERAL.TIME.LABEL.MONTHS', TimeTypeCode.Months),
            new MnbDropdownInputGroup('GENERAL.TIME.LABEL.QUARTERS', TimeTypeCode.Quarters),
            new MnbDropdownInputGroup('GENERAL.TIME.LABEL.YEARS', TimeTypeCode.Years),
        ];

        if (displayFiscalYearGroup) {
            result.push(new MnbDropdownInputGroup('GENERAL.TIME.LABEL.FISCAL_YEARS', TimeTypeCode.FiscalYears));
        }

        return new MnbDropdownInputGroupConfig(result, 'typeCode');
    }
}

export interface MnbQuickTimeFilterValue {
    timeFilter?: QuerySettingsTimeFilter;
    comparisonFilter?: QuerySettingsComparisonFilter;
}

type ExtendedTimeComparisonOption = {
    code?: TimeComparisonOptionCode;
    comparisonCode?: string;
    optionLabelCode?: string;
    labelCode?: string;
    shortLabelCode?: string;
    filter?: TimeComparisonFilter;
    extendedCode?: ExtendedComparisonCode;
    info?: string;
};
