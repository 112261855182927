import { Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChartRenderer } from '@shared-lib/modules/charts/renderers/chart-renderer';
import { BarChartConfig, ChartData, ChartDataArray, ChartDataType, ChartValue, DonutChartConfig } from '@shared-lib/modules/charts/services/chart.models';
import { MnbChartService } from '@shared-lib/modules/charts/services/chart.service';
import { MnbColor } from '@shared-lib/modules/core/utils/mnb-color-util';
import { ModelAttribute, ModelMeasure } from '@shared-lib/modules/model/mnb-model.model';
import { MnbModelService } from '@shared-lib/modules/model/services/mnb-model.service';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil, map } from 'rxjs/operators';
import { BrandShareReportDisplayModel, ReportsBrandShareService } from '../service/reports-brand-share.service';


@Injectable()
export class ReportsBrandShareChartService implements OnDestroy {

    private destroy$ = new Subject<void>();

    constructor(
        private brandShareService: ReportsBrandShareService,
        private chartService: MnbChartService,
        private modelService: MnbModelService,
        private translateService: TranslateService,
    ) {}

    public brandShareCharts$: Observable<BrandShareCharts> = this.brandShareService.viewModel$.pipe(
        filter(viewModel => viewModel.data !== null),
        map(viewModel => {
            const colorMap = this.createColorMap(viewModel);
            const columnRenderer = this.createColumnRenderer(viewModel, colorMap);
            const donutRenderer = this.createDonutRenderer(viewModel, colorMap);
            return {columnRenderer, donutRenderer};
        }),
        takeUntil(this.destroy$),
    );

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private createColorMap(viewModel: BrandShareReportDisplayModel): {[code: string]: number} {
        const data = viewModel.data;
        const shareAttributeCode = viewModel.shareAttributeCode;
        const shareMeasureCode = viewModel.shareMeasureCode;

        const dataList = data.rows.map(row => ({attributeValue: row.attributes[shareAttributeCode] , measureValue: row.values[shareMeasureCode]}));
        dataList.sort((a, b) => b.measureValue - a.measureValue);

        const colorMap: {[code: string]: number} = {};
        for (let i = 0; i < Math.min(dataList.length, 10); i++) {
            colorMap[dataList[i].attributeValue] = i;
        }
        return colorMap;
    }

    private createColumnRenderer(viewModel: BrandShareReportDisplayModel, colorMap: {[code: string]: number}): ChartRenderer {
        const data = viewModel.data;
        const availableAttributes = viewModel.availableAttributes;
        const availableMeasures = viewModel.availableMeasures;

        // column chart
        const shareAttributeCode = viewModel.shareAttributeCode;
        const shareMeasureCode = viewModel.shareMeasureCode;
        const attribute: ModelAttribute = availableAttributes[shareAttributeCode];
        const measure: ModelMeasure = availableMeasures[shareMeasureCode];

        // config
        const chartValue: ChartValue = new ChartValue();
        chartValue.code = shareMeasureCode;
        chartValue.label = measure.name;
        chartValue.unit = measure.unit.code;
        const chartConfig = new BarChartConfig();
        chartConfig.value = chartValue;
        chartConfig.breakdownMode = 'fill-stacked';
        chartConfig.seriesLabels = new Array<string>();
        chartConfig.limitXAxis = false;
        chartConfig.rotateXLabels = true;
        chartConfig.showTooltips = true;
        chartConfig.tickPadding = 20;

        // total data
        const totalData = data.chartTotals.data.map(entry => {
            return new ChartData(entry.chartAttributeValue, entry.measureValue);
        });
        chartConfig.data = [new ChartDataArray(ChartDataType.primary, 'total', totalData)];

        // main data
        data.chartBreakdowns.forEach(dataEntry => {
            const breakdownData = dataEntry.data.map(entry => {
                return new ChartData(entry.chartAttributeValue, entry.measureValue);
            });
            const label = dataEntry.shareAttributeValue;
            let color: string;
            if (Object.keys(colorMap).includes(label)) {
                const colorIdx = colorMap[label];
                color = this.modelService.getColor(attribute, label, colorIdx).color;
            } else {
                color = MnbColor.GREY_LIGHTER_1.color;
            }
            chartConfig.data.push(new ChartDataArray(ChartDataType.breakdown, label, breakdownData, null, color));
        });

        // other data
        const otherBreakdownData = data.chartOthers.data.map(entry => {
            return new ChartData(entry.chartAttributeValue, entry.measureValue);
        });
        const otherLabel = this.translateService.instant('GENERAL.LABEL.OTHER');
        const otherColor = MnbColor.GREY_LIGHTER_1.color;
        chartConfig.data.push(new ChartDataArray(ChartDataType.breakdown, otherLabel, otherBreakdownData, null, otherColor));

        // create or update renderer
        return this.chartService.createColumnChartRenderer(chartConfig);
    }

    private createDonutRenderer(viewModel: BrandShareReportDisplayModel, colorMap: {[code: string]: number}): ChartRenderer {
        const data = viewModel.data;
        const availableAttributes = viewModel.availableAttributes;
        const availableMeasures = viewModel.availableMeasures;

        // donut chart
        const shareAttributeCode = viewModel.shareAttributeCode;
        const shareMeasureCode = viewModel.shareMeasureCode;
        const attribute: ModelAttribute = availableAttributes[shareAttributeCode];
        const measure: ModelMeasure = availableMeasures[shareMeasureCode];

        // config
        const chartValue = new ChartValue();
        chartValue.code = shareMeasureCode;
        chartValue.label = measure.name;
        chartValue.unit = measure.unit.code;
        const chartConfig = new DonutChartConfig();
        chartConfig.value = chartValue;
        chartConfig.total = data.total.values[shareMeasureCode];

        // main data
        chartConfig.data = [new ChartDataArray(ChartDataType.primary, null, [])];
        chartConfig.data[0].data = data.rows.map(row => {
            const value = row.values[shareMeasureCode];
            const label = row.attributes[shareAttributeCode];
            let color: string;
            if (Object.keys(colorMap).includes(label)) {
                const colorIdx = colorMap[label];
                color = this.modelService.getColor(attribute, label, colorIdx).color;
            } else {
                color = MnbColor.GREY_LIGHTER_1.color;
            }
            return new ChartData(label, value, null, color);
        });

        // other data
        const otherLabel = this.translateService.instant('GENERAL.LABEL.OTHER');
        const otherColor = MnbColor.GREY_LIGHTER_1.color;
        chartConfig.data[0].data.push(new ChartData(otherLabel, data.other.values[shareMeasureCode], null, otherColor));

        // create or update renderer
        return this.chartService.createDonutChartRenderer(chartConfig);
    }
}

export type BrandShareCharts = {
    columnRenderer: ChartRenderer;
    donutRenderer: ChartRenderer;
};
