import { ModuleWithProviders, NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { MnbModelNamePipe } from './pipes/model-name.pipe';
import { MnbModelFieldClassesComponent } from './components/model-field-classes.component';
import { MnbCoreModule } from '../core/mnb-core.module';
import { TranslateModule } from '@ngx-translate/core';
import { MnbModelAttributeValueComponent } from './components/attribute-value/model-attribute-value.component';
import { MnbModelConfig } from '@shared-lib/modules/model/mnb-model.model';
import { MnbModelService } from '@shared-lib/modules/model/services/mnb-model.service';
import { MnbAttributePipe } from '@shared-lib/modules/model/pipes/attribute-model.pipe';
import { MnbMeasurePipe } from '@shared-lib/modules/model/pipes/measure-model.pipe';

@NgModule({
    declarations: [
        MnbModelFieldClassesComponent,
        MnbModelAttributeValueComponent,

        MnbModelNamePipe,
        MnbAttributePipe,
        MnbMeasurePipe,
    ],
    imports: [
        CommonModule,
        TranslateModule,
        MnbCoreModule
    ],
    exports: [
        MnbModelFieldClassesComponent,
        MnbModelAttributeValueComponent,

        MnbModelNamePipe,
        MnbAttributePipe,
        MnbMeasurePipe,
    ],
    providers: [
        MnbModelService,
        MnbModelNamePipe,
        MnbAttributePipe,
        MnbMeasurePipe,
    ]
})
export class MnbModelModule {
    public static forRoot(config: MnbModelConfig): ModuleWithProviders {
        return {
            ngModule: MnbModelModule,
            providers: [
                {provide: 'mnbModelConfig', useValue: config}
            ]
        };
    }

    public static forChild(): ModuleWithProviders {
        return {
            ngModule: MnbModelModule,
            providers: []
        };
    }
}

