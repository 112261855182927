import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { PortalReport } from '@minubo-portal/modules/api/models/api-report.model';
import { ReportData, ReportSettings } from '@shared-lib/modules/data/model/mnb-data-reports.model';
import { BehaviorSubject } from 'rxjs';
import { ReportsTemplateDataChangeEvent, ReportsTemplateFormValue, ReportsTemplateViewSettingsAppliedEvent } from '../template/reports-template.component';
import { TraceClassDecorator } from '@sentry/angular';


@TraceClassDecorator()
@Component({
    selector: 'reports-inventory',
    templateUrl: './reports-inventory.component.html'
})
export class ReportsInventoryComponent implements OnInit, OnDestroy {

    public form: FormGroup;

    public viewSettings$ = new BehaviorSubject<ReportSettings>(null);

    public report: PortalReport;
    public data: ReportData;
    public dataViewSettings: ReportSettings;

    public enableComparison = false;

    ngOnInit(): void {

        this.form = new FormGroup({
            'timeFilter': new FormControl(null),
            'filters': new FormControl([]),
        });
    }

    onViewSettingsApplied(event: ReportsTemplateViewSettingsAppliedEvent) {
        const settings = event.viewSettings || event.report.settings;

        this.form.get('timeFilter').setValue(settings.inventory.timeFilter);
        this.form.get('filters').setValue(settings.inventory.filters || []);
    }

    onViewSettingsFormValueChanged(formValue: ReportsTemplateFormValue) {
        const viewSettings = new ReportSettings();
        viewSettings.inventory = {
            timeFilter: formValue.timeFilter,
            filters: formValue.filters
        };
        this.viewSettings$.next(viewSettings);
    }

    onDataChanged(event: ReportsTemplateDataChangeEvent) {
        this.report = event.report;
        this.data = event.data;
        this.dataViewSettings = event.viewSettings;
    }

    ngOnDestroy(): void {

    }

}
