import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MnbBusyStatus } from '@shared-lib/modules/core/model/mnb-core-busy-status.model';
import { deepCopy } from '@shared-lib/modules/core/utils/deep-copy.util';
import { QueryFilter } from '@shared-lib/modules/data/model/mnb-data-query.model';
import { filterTypeOptions, QueryFilterTypeOption } from '@shared-lib/modules/queries/model/query-filter.model';
import { FilterService } from '@shared-lib/modules/queries/services/filter-service';
import deepEqual from 'deep-equal';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, map, startWith, filter, take, takeUntil, distinctUntilChanged } from 'rxjs/operators';
import { MediaService } from '@minubo-suite/shared/services/media/media.service';

@Component({
    templateUrl: './mnb-quick-filter.component.html',
    selector: 'mnb-quick-filter'
})
export class MnbQuickFilterComponent implements OnInit, OnDestroy {

    @Input() currentlyAppliedFilter: QueryFilter;
    @Input() title: string;
    @Input() originalFilter: QueryFilter;
    @Input() valueLoader: (attributeCode: string, filterSearch?: string) => Promise<FilterAttributeValues>;
    @Input() dropdownRight: boolean;

    @Output() filterChange = new EventEmitter<QueryFilter>();

    public model: QuickFilterValueSelectorModel;
    public valuesLabel: string;
    public suggestionsLoadingStatus: MnbBusyStatus = new MnbBusyStatus();
    public filterValueInput: string;
    public showResetButton: boolean;
    public dropdownRight$: Observable<boolean>;
    public hasUnsavedChanges$ = new BehaviorSubject(false);
    public searchInputSubject: Subject<string> = new Subject<string>();

    // Search timeout (ms)
    private searchTimeout = 250;

    @ViewChild('container', { static: true }) container: ElementRef;

    public isOpened$ = new BehaviorSubject(false);
    private destroy$ = new Subject<void>();

    constructor(
        private filterService: FilterService,
        private elRef: ElementRef,
        private mediaService: MediaService,
    ) {}

    public ngOnInit(): void {

        this.model = new QuickFilterValueSelectorModel(
            this.currentlyAppliedFilter.values,
            {hasMore: false, values: []},
            this.currentlyAppliedFilter.typeCode,
            this.currentlyAppliedFilter.attributeCode
        );

        this.searchInputSubject.pipe(
            debounceTime(this.searchTimeout),
            takeUntil(this.destroy$)
            ).subscribe((searchInput) => {
            this.updateModelFilterAttributeValues(searchInput);
        });

        this.suggestionsLoadingStatus.done();

        this.valuesLabel = this.filterService.createFilterLabel(this.currentlyAppliedFilter, this.container.nativeElement);

        this.dropdownRight$ = this.mediaService.viewSize$.pipe(
            startWith(false),
            map(_ => {
                const elementRect = this.elRef.nativeElement.getBoundingClientRect();
                const spaceLeft = elementRect.left;
                const spaceRight = window.innerWidth - elementRect.right;
                return spaceRight < spaceLeft;
            })
        );

        this.model.editForm.valueChanges.pipe(
            startWith(this.model.editForm.value),
            map(value => !deepEqual(value, this.currentlyAppliedFilter)),
            takeUntil(this.destroy$) // Important: don't forget to unsubscribe
        ).subscribe(hasChanges => {
            this.hasUnsavedChanges$.next(hasChanges);
        });

        this.isOpened$.pipe(
            filter( isOpen => isOpen),
        ).subscribe(_ => {
            this.searchInputSubject.next('');
            this.showResetButton = !deepEqual(this.originalFilter, this.model.editForm.value);
        });
        this.isOpened$.pipe(
            distinctUntilChanged(),
            filter(isOpen => isOpen === false),
            takeUntil(this.destroy$)
        ).subscribe(() => {
            if (this.hasUnsavedChanges$.getValue() && !deepEqual(this.model.editForm.value, this.currentlyAppliedFilter.values) ) {
                this.model.setFilter(this.currentlyAppliedFilter);
            }
        });
    }

    public onCancel(e: MouseEvent) {
        e.stopPropagation();
        this.model.setFilter(this.originalFilter);
        this.isOpened$.next(false);
    }

    public onApplyChanges(e: Event): void {
        e.stopPropagation();
        const currentFilter = this.model.editForm.value;
        this.showResetButton = !deepEqual(this.originalFilter, currentFilter);
        this.filterChange.emit(currentFilter);
        this.valuesLabel = this.filterService.createFilterLabel(currentFilter, this.container.nativeElement);
        this.hasUnsavedChanges$.next(false);
    }

    public onClick() {
        this.isOpened$.next(!this.isOpened$.value);
    }

    public onOutsideClick() {
        this.isOpened$.next(false);
    }

    public onSearchInputModelChange(searchInput: string) {
        this.searchInputSubject.next(searchInput);
    }

    private updateModelFilterAttributeValues(searchInput: string) {
        this.suggestionsLoadingStatus = new MnbBusyStatus();
        const formValues = deepCopy(this.model.editForm.controls.values.value);
        this.valueLoader(this.currentlyAppliedFilter.attributeCode, searchInput).then(filterAttributeValues => {
            filterAttributeValues.values = filterAttributeValues.values.filter(filterAttributeValue => {
                return !formValues.includes(filterAttributeValue);
            });
            this.model.updateAttributeValues(filterAttributeValues);
        }).finally(() => {
            this.suggestionsLoadingStatus.done();
        });
    }

    public onFilterAttributeValueSelect(index: number): void {
        const formValues = deepCopy(this.model.editForm.controls.values.value);
        formValues.push(this.model.filterAttributeValues.values.splice(index, 1)[0]);
        this.model.editForm.controls.values.setValue(formValues);
    }

    public onFilterValueRemove(index: number): void {
        const formValues = deepCopy(this.model.editForm.controls.values.value);
        this.model.sortUpdateFilterAttributeValues(formValues.splice(index, 1).concat(this.model.filterAttributeValues.values));
        this.model.editForm.controls.values.setValue(formValues);
    }

    public useFilterValueOnly(index: number) {
        const formValues = deepCopy(this.model.editForm.controls.values.value);
        this.model.editForm.controls.values.setValue([formValues[index]]);
        formValues.splice(index, 1);
        this.model.sortUpdateFilterAttributeValues(formValues.concat(this.model.filterAttributeValues.values));
    }

    public useFilterAttributeValueOnly(index: number) {
        const usedValues: string[] = [...this.model.editForm.controls.values.value];
        const values = this.model.filterAttributeValues.values;
        this.model.editForm.controls.values.setValue([values[index]]);
        this.model.sortUpdateFilterAttributeValues(values.slice(0, index).concat(values.slice(index + 1)).concat(usedValues));
    }

    public clearFilterValues(): void {
        const formValues = deepCopy(this.model.editForm.controls.values.value);
        this.model.editForm.controls.values.setValue([]);
        this.model.sortUpdateFilterAttributeValues(formValues.concat(this.model.filterAttributeValues.values));
    }

    public addFilterValue(): void {
        const formValues = deepCopy(this.model.editForm.controls.values.value);
        formValues.push(this.filterValueInput);
        this.model.editForm.controls.values.setValue(formValues);
        this.filterValueInput = '';
    }

    public onReset(e: Event): void {
        this.model.setFilter(this.originalFilter);
        this.onApplyChanges(e);
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
        this.isOpened$.complete();
        this.hasUnsavedChanges$.complete();
        this.searchInputSubject.complete();
    }
}

export interface FilterAttributeValues {
    hasMore: boolean;
    values: Array<string>;
}

export class QuickFilterValueSelectorModel {
    public filterTypeOptions: QueryFilterTypeOption[];
    public isEqualsFilterType: boolean;
    public editForm: FormGroup;

    constructor(
        public filterValues: Array<string>,
        public filterAttributeValues: FilterAttributeValues,
        public filterTypeCode: string,
        public filterAttributeCode: string
    ) {
        this.filterTypeOptions = filterTypeOptions;
        this.isEqualsFilterType = filterTypeOptions.find(filterTypeOption => filterTypeOption.code === filterTypeCode).isEqualsFilterType;

        const typeCodeControl = new FormControl(this.filterTypeCode);
        typeCodeControl.valueChanges.subscribe((typeCode) => {
            this.isEqualsFilterType = filterTypeOptions.find(filterTypeOption => filterTypeOption.code === typeCode).isEqualsFilterType;
        });
        this.editForm = new FormGroup({
            attributeCode: new FormControl(filterAttributeCode),
            typeCode: typeCodeControl,
            values: new FormControl(this.filterValues)
        });
    }

    public setFilter(targetFilter?: QueryFilter): void {
        this.editForm.controls.attributeCode.setValue(targetFilter.attributeCode, { emitEvent: false });
        this.editForm.controls.values.setValue(targetFilter.values, { emitEvent: false });
        this.editForm.controls.typeCode.setValue(targetFilter.typeCode, { emitEvent: false });
    }

    public updateAttributeValues(filterAttributeValues: FilterAttributeValues): void {
        this.filterAttributeValues = filterAttributeValues;
    }

    public sortUpdateFilterAttributeValues(unsortedValues: string[]) {
        this.filterAttributeValues.values = [...unsortedValues].sort((a, b) => a.localeCompare(b));
    }

}
